<template>
    <div class="pty-reservation">
        <div class="pty-reservation__hole"/>
        <div class="pty-reservation__ticket top">
            <div class="pty-reservation__ticket__header">
                <div class="pty-reservation__ticket__header__title">
                    <h2>{{ reservation.event }}</h2>
                    <p>{{ reservation.location }}</p>
                </div>
                <div class="pty-reservation__ticket__header__date">
                    <h2>{{ reservation.date }}</h2>
                    <p>{{ reservation.month }}</p>
                </div>
            </div>
            <hr />

            <div class="pty-reservation__ticket__details">
                <!-- frist row contains Nome and Inizio-->
                <div class="pty-reservation__ticket__details__row">
                    <div class="pty-reservation__ticket__details__row__item">
                        <p class="label">Nome</p>
                        <p>{{ reservation.partecipants[0].name }}</p>
                    </div>
                    <div class="pty-reservation__ticket__details__row__item">
                        <p class="label">Cognome</p>
                        <p>{{ reservation.partecipants[0].surname }}</p>
                    </div>
                </div>
                <div class="pty-reservation__ticket__details__row">
                    <div class="pty-reservation__ticket__details__row__item">
                        <p class="label">Inizio</p>
                        <p>{{ (new Date(reservation.fullEvent.start_at)).toLocaleTimeString() }}</p>
                    </div>
                    <div class="pty-reservation__ticket__details__row__item">
                        <p class="label">Partecipanti</p>
                        <p>{{ reservation.partecipants.length }}</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="pty-cut">
            <div class="bottom">
                <div></div>
                <div></div>
            </div>
            <hr/>
            <div class="up">
                <div></div>
                <div></div>
            </div>
        </div>

        <div class="pty-reservation__ticket bottom">
            
            <AppleWallet @click="downloadPKPass"/>
            <AppleCalendar @click="downloadICAL"/>
        </div>


        
        
    </div>
</template>
<script>
const ics = require('ics');
import apiClient from '@/rest/client'
import AppleWallet from '../assets/images/icons/apple-wallet.svg';
import AppleCalendar from '../assets/images/icons/apple-calendar.svg';
export default {
    name: 'PtyReservation',
    props: {
        reservation: {
            type: Object,
            default: () => {}
        }
    },

    components: {
        AppleWallet,
        AppleCalendar
    },

    methods: {
        downloadPKPass(){
            const url = apiClient.defaults.baseURL + `/reservations/${this.reservation.id}/pass`;
            
            // create a
            var a = document.createElement('a');
            a.href = url
            a.download = 'pass.pkpass';

            // append to body
            document.body.appendChild(a);
            a.click();

            // remove from body
            document.body.removeChild(a);
        },

        downloadICAL(){
            // full event.start_at and event.end_at contains the date and time

            let start = new Date(this.reservation.fullEvent.start_at);
            let end = new Date(this.reservation.fullEvent.end_at);

            const event = {
                start: [start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes()],
                end: [end.getFullYear(), end.getMonth(), end.getDate(), end.getHours(), end.getMinutes()],
                title: this.reservation.event,
                location: this.reservation.fullEvent.business.address + ' ' + this.reservation.fullEvent.business.city,
            }

            ics.createEvent(event, (error, value) => {
                if (error) {
                    console.log(error)
                    return
                }

                const blob = new Blob([value], {type: 'text/calendar'});
                const url = window.URL.createObjectURL(blob);

                // create a
                var a = document.createElement('a');
                a.href = url
                a.download = 'event.ics';

                // append to body
                document.body.appendChild(a);
                a.click();

                // remove from body
                document.body.removeChild(a);
            });
        }
    }
}
</script>
<style scoped>
h2,p {
    margin: 0;
}
.pty-reservation {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.pty-reservation__hole {
    border-radius: 3.125rem;
    background: rgba(13, 65, 71, 0.126);
    box-shadow: 0px 6px 4px 0px rgba(17, 24, 39, 0.15) inset;
    width: 100%;
    height: 20px;
    position: absolute;
    top: 0;
}


.pty-reservation__ticket.top {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    z-index: 100;
    margin-top: 5px;
}

.pty-reservation__ticket.bottom {
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    justify-content: space-between;
    align-items: center!important;
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.pty-reservation__ticket {
    
    background: white;
    width: 90%;
    padding: 30px 20px;
    box-sizing: border-box;
    box-shadow: 0px 6px 20px 0px rgba(17, 24, 39, 0.15);
}


.pty-cut {
    width: 90%;
    z-index: 100;
    position:relative;
}
.pty-cut div {
    display: flex;
    width: 100%;
}
.pty-cut div.up div:first-child {
    height: 20px;
    width: 50%;
    -webkit-mask-image: radial-gradient(circle 10px at 0% 0%, transparent 0, transparent 20px, black 21px);
    background: white;
    box-shadow: 0px 6px 4px 0px rgba(17, 24, 39, 0.15);
}

.pty-cut div.up div:nth-child(2) {
    height: 20px;
    width: 50%;
    -webkit-mask-image: radial-gradient(circle 10px at 100% 0%, transparent 0, transparent 20px, black 21px);
    background: white;
    box-shadow: 0px 6px 4px 0px rgba(17, 24, 39, 0.15);
}


.pty-cut div.bottom div:first-child {
    height: 20px;
    width: 50%;
    -webkit-mask-image: radial-gradient(circle 10px at 0% 100%, transparent 0, transparent 20px, black 21px);
    background: white;
    box-shadow: 0px 6px 4px 0px rgba(17, 24, 39, 0.15);
}

.pty-cut div.bottom div:nth-child(2) {
    height: 20px;
    width: 50%;
    -webkit-mask-image: radial-gradient(circle 10px at 100% 100%, transparent 0, transparent 20px, black 21px);
    background: white;
    box-shadow: 0px 6px 4px 0px rgba(17, 24, 39, 0.15);
}

.pty-reservation__ticket__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pty-reservation__ticket__header__title {
    display: flex;
    flex-direction: column;
}

.pty-reservation__ticket__header__date {
    display: flex;
    flex-direction: column;
}

.pty-reservation__ticket__header__date h1 {
    font-size: 1.5rem;
    color: #000;
}

.pty-reservation__ticket__header__date p, .pty-reservation__ticket__header__title p {
    font-size: 1rem;
    color: #AAA;
}

.pty-reservation__ticket hr {
    border: none;
    border-top: 2px dashed #AAA;
    margin: 20px 0;
    height: 0;
    border-top-width: 1px; /* Thickness of the dash */
    border-image: repeating-linear-gradient(
        to right,
        #AAA 0,
        #AAA 10px,
        transparent 10px,
        transparent 20px
    ) 3; /* Adjust the dash and gap sizes */
}


.pty-cut hr {
    position: absolute;
    z-index: 100;
    width: calc(100% - 40px);
    left: 20px;
    top: 0;
    border: none;
    border-top: 2px dashed #AAA;
    margin: 20px 0;
    height: 0;
    border-top-width: 1px; /* Thickness of the dash */
    border-image: repeating-linear-gradient(
        to right,
        #AAA 0,
        #AAA 10px,
        transparent 10px,
        transparent 20px
    ) 3; /* Adjust the dash and gap sizes */
}

.pty-reservation__ticket__details {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.pty-reservation__ticket__details__row__item .label {
    color: #AAA;
}

.pty-reservation__ticket__details__row__item p:not(.label){
    font-weight: bold;
}

.pty-reservation__ticket__details__row__item {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 40%;
}

.pty-reservation__ticket__details__row {
    display: flex;
    justify-content: space-between;
}
</style>