<template>
    <div class="pty-back" :class="{ 'primary': primary, 'search': search }">
        <PtyButton transparent :no-padding="true" @click="back" :loading="loading" :circle="!primary" v-show="!disabled" id="pty__back">
            <BackArrow v-if="!primary" />
            <BackArrowPrimary v-else />

            <p v-if="primary">Indietro</p>
        </PtyButton>

        <PtyButton :transparent="!searchPrimary" circle :no-padding="true" @click="toggleSearch" :loading="loading" v-if="search" style="width:44px; height:44px; ">
            <i class="bx bx-sm bx-search" style="height:100%;"/>
        </PtyButton>
    </div>
</template>

<script>
import PtyButton from './PtyButton.vue';
import BackArrow from '../assets/images/icons/back-arrow.svg';
import BackArrowPrimary from '../assets/images/icons/back-arrow-primary.svg';
export default {
    name: 'PtyBack',
    components: {
        PtyButton,
        BackArrow,
        BackArrowPrimary
    },
    data() {
        return {
            loading: false       
        }
    },

    props: {
        primary: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        },

        search: {
            type: Boolean,
            default: false
        },

        searchPrimary: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        back() {
            this.$emit('back');
        },

        toggleSearch() {
            this.$emit('searchToggle');
        }
    }
}
</script>

<style>
.pty-back .vs-button {
    border: none;
}
.pty-back {
    display: flex;
    width: calc(100% - 40px);
    margin-top: 5px;
    position: absolute;
    top: 10px;
    left: 20px;
    justify-content: flex-start;
}

.pty-back.search {
    justify-content: space-between;
}

.pty-back p {
    font-family: "Sofia Pro";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.04125rem;
    background: var(--Linear, linear-gradient(12deg, #00A9C0 0.95%, rgba(0, 169, 192, 0.63) 101.3%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 0.5rem;
}

div:has(.pty-back) > *:not(.pty-back):not(.pty-next) {
    padding-top: 3.25rem!important;
}

</style>